a.breadcrumb {
	display: inline-flex;
	line-height: 1;
	margin-bottom: 24px;
	svg {
		margin: 0 6px;
	}

	&.orange {
		color: #FF7F00;
		path {
			fill: #FF7F00;
		}
	}

	&:active {
		color: #FF7F00;
		path {
			fill: #FF7F00;
		}
	}
}

.agrees {
    margin-top: 5px;
        .agree {
            & span {
            cursor: pointer;
            color: #ff7f00;
            }
        }
}

   .sign-up-form {
       display: grid;
       grid-template-columns: 124px 360px;
       grid-template-rows: 40px 40px 40px 40px 40px 0px;
       row-gap: 24px;
       padding-top: 15px;
       font: normal 16px 'Roboto', sans-serif;
       color: #5c5e66;
       align-items: center;

        .error-message {
            color: #ff0000;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin: 6px 0 0 0;
        }

        .input-text {
            & > span {
            padding: 0;
            border: none;
            border-radius: 4px;
            }
        }

        .input-text {
            & span span {
            position: absolute;
            top: 7px;
            right: 5px;
            }
        }

        & input[type='text'],
        input[type='password'],
        input[type='email'] {
            width: 100%;
            height: 40px;
            padding: 0 12px;
            background: #ffffff;
            border: 1px solid #dde3e8;
            border-radius: 4px;
            outline-width: 0;
        }

        & button {
            margin: 20px 0 0 20px;
            border-radius: 4px;
        }

        & .buttons {
            display: flex;
            justify-content: flex-start;

            & .button-orange {
            order: -1;
            padding: 0 16px;
            margin: 20px 0 0 0;
            }

            & .button-white {
            padding: 0 14px;
            }
        }
   }
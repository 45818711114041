.aboutProject {
    background: #FBFBFC;
    border: 1px solid #EFEFF3;
    border-radius: 4px;

    &-header {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 24px;

        h2 {
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    &-content {
        padding: 20px 24px;
        
        .title {
            color: #333333;
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 20px;
            
            .grey {
                color: #8C8C8C;
                margin-right: 8px;
            }
            
            p {
                margin-bottom: 16px;
            }

            &:last-child {
                margin-bottom: 0;

                p {
                    margin: 0;
                }
            }
        }
        
        .tag {
            border: 1px solid #8D8F9B;
            margin-right: 12px;
            margin-top: 8px;
        }
    }
}
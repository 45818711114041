.user-authorized {
  display: flex;
  align-items: center;

  & .avatar {
    margin: 0 15px 0 0;
    font-size: 0;
    background: #E6E7E7;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  & .user-data {
    min-width: 180px;

    & h4 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }

    & p {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 136%;
      color: #434449;
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    padding: unset;
    span{
      text-align: center;
      font-weight: 400;
      font-size: 16px;
    }
    
    .ant-dropdown-menu-item {
      height: 36px;
      &:hover {
        background-color: #faf2e8;
      }
    }
    
  }
}
.contactUsModal {
    &.ant-modal {
        min-width: 1070px;
    }

    .ant-modal-close {
        padding: 24px 16px;
    }

    .ant-modal-body {
        padding-bottom: 74px;
    }

    .ant-row {
        max-height: 60vh;
        overflow: auto;
    }

    &-header {
        margin-bottom: 30px;
        h1 {
            margin-bottom: 10px;
        }

        span {
            color: #5F6469;
            font-size: 20px;
        }
    }
    .aboutProject {
        margin-bottom: 20px;

        &-header {
            border-bottom: 1px solid #EFEFF3;
        }

        &-content {
            background: #ffffff;
        }
    }

    .contactInfo {
        margin-bottom: 34px;
        &-header {
            border-bottom: 1px solid #EFEFF3;
        }

        &-content {
            background: #ffffff;
        }
    }

    .footer-project {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);

    .spinner {
        width: 88px;
        height: auto;
        flex-wrap: wrap;
    }

    .loader {
        position: relative;

        &.orange {
            fill: #FF7F00;
            z-index: 2;
        }

        &.green {
            fill: #007F67;

        }

        &.light-green {
            fill: #9DE500;
            z-index: 2;
        }
    }

    .tl {
        animation: tl 5s infinite ease-in-out;
    }
    .tr {
        animation: tr 5s infinite ease-in-out;
    }
    .br {
        animation: br 5s infinite ease-in-out;
    }
    .bl {
        animation: bl 5s infinite ease-in-out;
    }
}

  @keyframes tl {
    0% {transform: translate(0, 13px);}
    10% {transform: translate(-13px, 0);}
    40% {transform: translate(-13px, 0);}
    50% {transform: translate(-13px, 0) rotate(-180deg);}
    80% {transform: translate(-13px, 0) rotate(-180deg);}
    90% {transform: translate(0, 13px) rotate(180deg);}
    100% {transform: translate(0, 13px);}
  }

  @keyframes tr {
    0% {transform: translate(0, 13px);}
    10% {transform: translate(0, 13px);}
    20% {transform: translate(-13px, 0);}
    50% {transform: translate(-13px, 0);}
    60% {transform: translate(-13px, 0) rotate(-180deg);}
    80% {transform: translate(-13px, 0) rotate(-180deg);}
    90% {transform: translate(0, 13px) rotate(180deg);}
    100% {transform: translate(0, 13px);}
  }

  @keyframes br {
    0% {transform: translate(0, -13px) rotate(180deg);}
    20% {transform: translate(0, -13px) rotate(180deg);}
    30% {transform: translate(13px, 0) rotate(180deg);}
    70% {transform: translate(13px, 0) rotate(180deg);}
    80% {transform: translate(13px, 0) rotate(0deg);}
    90% {transform: translate(0, -13px) rotate(0deg);}
    100% {transform: translate(0, -13px) rotate(180deg);}
  }

  @keyframes bl {
    0% {transform: translate(0, -13px) rotate(180deg);}
    30% {transform: translate(0, -13px) rotate(180deg);}
    40% {transform: translate(13px, 0) rotate(180deg);}
    60% {transform: translate(13px, 0) rotate(180deg);}
    70% {transform: translate(13px, 0) rotate(0deg);}
    80% {transform: translate(13px, 0) rotate(0deg);}
    90% {transform: translate(0, -13px) rotate(360deg);}
    100% {transform: translate(0, -13px) rotate(180deg);}
  }

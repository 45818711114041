.tag {
    display: inline-flex;
    height: 28px;
    min-width: fit-content;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    background: #E6E7E7;
    color: #404549;
    border-radius: 20px;

    &.hidden {
        display: none;
    }
}
.teamRecommended {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-title, .header-subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            color: #d2d3d4;
            font-size: 18px;

            b {
                font-size: 24px;
                color: #FD7016;
            }
        }
    }

    .header-title {
        margin-bottom: 18px;
    }

    &-header {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;

        h3, h4 {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: 0;
            padding: 0;
        }

        h4 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 6px;
        }
    
        p {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            margin: 0;
            padding: 0;
            opacity: 0.8;
        }

        .team-value {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            background: #FF7F00;
            padding: 1px 10px;
            border-radius: 16px
        }

        .project-info {
            div {
                color: #8C8C8C;
                &>span {
                    color: #333333;
                    margin-right: 5px;
                }
            }
        }
    }

    .team-list {
        display: flex;
        flex-grow: 1;
        overflow: auto;
        flex-direction: column;

        .ant-radio-group {
            width: 100%;
        }
        .team-item {
            width: 100%;
            margin-bottom: 8px;
            border: none;
            background: #F6F6F8;
            
            &:focus-within{
                box-shadow: none;
            }

            &::before {
                display: none;
            }
            
            span{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 16px;
                
                div {
                    display: flex;
                    align-items: center;
                }
                .ant-radio-button {
                    display: none;
                }
            }

            input {
                width: 60px;
                height: 24px;
                margin-right: 8px;
            }


            &.ant-radio-button-wrapper {
                path {
                    fill: #D3D3D3;
                }
                &:hover {
                    color: #ffffff;
                    background: #1BAE92;
                }

                &-checked, &-checked:hover {
                    color: #ffffff;
                    background: #007F67;
                font-weight: 500;

                    path {
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}
.header {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  background: #FBFBFC;
  border-bottom: 1px solid #E6E7E7;
  position: fixed;
  width: 100%;
  z-index: 20;
  padding-right: 32px;

  .img-wrapper {
    padding-left: 32px;
    flex: 0 0 330px;
  }

  img {
    cursor: pointer;
  }

  & button {
    width: 128px;
  }
}
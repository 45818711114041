@mixin font-input {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

@mixin font-textarea {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.contactInfo {
    background: #FBFBFC;
    border: 1px solid #EFEFF3;
    border-radius: 4px;

    .term {
        display: flex;
        align-items: center;
        p {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin: 0;
         }
    
         .agree {
            margin: 0 0 0 14px;
    
            & span {
               cursor: pointer;
               color: #ff7f00;
            }
         }
    }

    &-header {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 24px;

        h2 {
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    &-content {
        padding: 20px 24px;

        .title {
            color: #333333;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;

            span {
                line-height: 20px;
                margin-bottom: 4px;

                &.grey {
                    color: #8C8C8C;
                }
            }

            p {
                margin-bottom: 16px;
            }

            input {
                margin-top: 4px;
                @include font-input;
            }

            textarea {
                margin-top: 4px;
                @include font-textarea;
            }
        }
    }
}
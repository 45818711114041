.checkemail {
   display: grid;
   grid-template-columns: 484px;
   grid-template-rows: 40px 40px;
   row-gap: 24px;
   padding: 0 40px;
   text-align: center;
   font: normal 12px/16px 'Roboto', sans-serif;
   margin: 0 0 0 14px;

   button {
      width: 144px;
   }
}

.agrees {
   margin-top: 5px;
   font-size: 0;
   display: flex;
   align-items: center;

   .agree {
      margin: 0 0 0 14px;

      & span {
         cursor: pointer;
         color: #ff7f00;
      }
   }
}

.sign-form {
   .sign-up-form {
      display: grid;
      grid-template-columns: 124px 360px;
      grid-template-rows: 40px 40px 40px 40px 40px 0px;
      row-gap: 24px;
      padding-top: 15px;
      font: normal 16px 'Roboto', sans-serif;
      color: #5c5e66;
      align-items: center;
   
      .error-message {
         color: #ff0000;
         font-weight: 400;
         font-size: 12px;
         line-height: 16px;
         margin: 6px 0 0 0;
      }
   
      .input-text {
         & > span {
            padding: 0;
            border: none;
            border-radius: 4px;
         }
      }
   
      .input-text {
         & span span {
            position: absolute;
            top: 7px;
            right: 5px;
         }
      }
   
      & input[type='text'],
      input[type='password'],
      input[type='email'] {
         width: 100%;
         height: 40px;
         padding: 0 12px;
         background: #ffffff;
         border: 1px solid #dde3e8;
         border-radius: 4px;
         outline-width: 0;
      }
   
      & input[type='checkbox'] {
         cursor: pointer;
         width: 15px;
         height: 15px;
         background: #bbc0c5;
      }
   
      & input[type='password'] {
         & span {
            border: 1px solid #ff7f00;
         }
      }
   
      & button {
         margin: 20px 0 0 20px;
         border-radius: 4px;
      }
      & .buttons {
         display: flex;
         justify-content: flex-start;
   
         & .button-orange {
            order: -1;
            padding: 0 16px;
            margin: 20px 0 0 0;
         }
   
         & .button-white {
            padding: 0 14px;
         }
      }
   }
}

.info-form {
   display: grid;
   align-items: center;
   grid-template-columns: 33px 450px;
   margin-bottom: 24px;

   & .info-icon {
      width: 22px;
      height: 22px;
   }
}

.verificationEmailSuccess {
   text-align: center;
   display: grid;
   grid-template-columns: 484px;
   grid-template-rows: 40px 40px;
   row-gap: 24px;
   padding: 0px 40px;
}

.email-verification-wrapper {
   margin-top: -14px;
   display: flex;
   align-items: center;
   & > button {
      color: #ff7f00;
      margin: 0 !important;
   }
}

.label-for-email-code {
   font-size: 12px;
   margin-bottom: 3px;
}

.ant-input-disabled {
   background-color: #fafafa !important;
}

.verification-email-label {
   margin-top: -14px;
}

.verification-email {
   margin-top: -10px;
   span {
      background: #fff8e2;
      padding: 4px 16px;
      border-radius: 4px;
      font-size: 14px;
   }
}

.email-verified-block {
   display: flex;
   align-items: center;
   padding-left: 10px;
   color: #007f67;
   > span {
      padding-right: 10px;
   }
}

.timer {
   margin-top: 10px;
   font-weight: 400;
   font-size: 14px;
   > span {
      color: #ff7f00;
   }
}
.exitModal {
    &.ant-modal {
        min-width: 720px;

        .ant-modal-body {
            padding: 0 0 74px;
        }
    }

    .ant-modal-close {
        padding: 24px 16px;
    }



    &-header {
        padding: 24px 32px;
        border-bottom: 1px solid #E4E4EB;

        h2 {
            margin: 0;
            font-size: 32px;
            line-height: 40px;
        }

        span {
            color: #5F6469;
            font-size: 20px;
        }
    }

    &-content {
        padding: 24px 32px;
        min-height: 147px;

        p {
            font-size: 18px;
            line-height: 21px;
            color: #404549;
            max-width: 558px;
            margin-bottom: 12px;

        }
    }

    .footer-project {
        position: absolute;
        left: 0;
        bottom: 0;
        box-shadow: unset;
        
        button {
            margin: 0 24px 0 0;
        }
    }
}
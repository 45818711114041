.ant-divider {
  margin: unset;
}
.ant-divider-vertical {
  border-left: 1px solid #8D8F9B;
}

.ant-layout {
  background: unset;
}

.ant-tooltip {
  z-index: 10;
  &-arrow {
    display: none;
  }
  &-inner {
    background-color: #ffffff;
    color: #434449;
    box-shadow: 0px 2px 6px rgba(64, 69, 73, 0.1);
    padding: 4px 8px;
  }
}
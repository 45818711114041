.teamOrder {
  border: 1px solid #EFEFF3;
  border-radius: 4px;

  &-header {
    background: #FBFBFC;
    border-radius: 4px;
    border-bottom: 1px solid #EFEFF3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 24px;

      h2 {
        font-weight: 500;
        font-size: 24px;
        color: #434449;
        margin: 0 8px 0 0;
      }
  }

  &-list {
    padding: 20px 25px 0 25px;
    max-height: 562px;
    overflow-y: auto;
  }

  &-category {
    &-header{
      margin-bottom: 8px;
      
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        
        span {
          font-size: 14px;
          color: #8D8F9B;

          >span {
            color: #1A1A1D;
          }
        }
      }
    }
  }

  &-item {
    margin: 0 0 20px 0;
    
    .item-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    p {
      margin: 0;
      color: #8D8F9B;
    }

    span {
      color: #1A1A1D;
    }

    &.custom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .item-info {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        margin: 0;
        align-items: unset;
        justify-content: unset;

        &-name {
          color: #434449;
        }
      }

      button {
        margin-left: 65px;
        font-size: 14px;

        path {
          fill: #d3d3d3
        }
      }
    }
  }
}
@import '~antd/dist/antd.css';
@import './Antd.scss';

@mixin font-button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

@mixin font-input {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}


.button-white {
  background: #FFFFFF;
  border: 1px solid #434449;
  border-radius: 4px;
  @include font-button;
  color: #434449;
  height: 40px;
  padding: 0 40px;
  cursor: pointer;
  transition: all 0.3s;

  & a {
    color: #434449;
  }

  &:hover {
    border: 1px solid #2D1940;
    box-shadow: 0 4px 12px rgba(67, 68, 73, 0.12);
    color: #280946;
  }
}

.button-orange {
  @include font-button;
  display: flex;
  align-items: center;
  background: #FF7F00;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  height: 40px;
  padding: 0 40px;
  cursor: pointer;

  &:hover {
    background: #FDA248;
    color: #FFFFFF;
  }

  & a {
    color: #FFFFFF;
  }

  &.big {
    height: 51px;
  }
  
  &:disabled {
    background: #fda34885;
  }

  &.secondary {
    background: transparent;
    color: #FF7F00;
    border: 1px solid #FF7F00;

    svg {
      margin-left: 10px;
      height: 18px;
      width: 18px;
      path {
        fill: #FF7F00;
      }
    }

    &:hover {
      box-shadow: 0 4px 12px rgba(67, 68, 73, 0.12);
      
    }
  }
}

.button-green {
  @include font-button;
  background: #007F67;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  height: 36px;
  padding: 0 36px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    height: 16px;
    path {
      fill: #ffffff;
    }
  }

  &:hover {
    background: #1BAE92;
  }

  &.secondary {
    background: transparent;
    color: #006A56;
    border: 1px solid #006A56;

    svg {
      margin-left: 4px;
      height: 16px;
      path {
        fill: #006A56;
      }
    }
  }

  &.link {
    background: transparent;
    padding: 0;
    color: #006A56;
    font-size: 16px;
  }

  & a {
    color: #FFFFFF;
  }
}

.button-red {
  @include font-button;
  background: #F44532;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  height: 36px;
  padding: 0 36px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    height: 16px;
    path {
      fill: #ffffff;
    }
  }

  &:hover {
    background: #F44532;
  }

  &.secondary {
    background: transparent;
    color: #F44532;
    border: 1px solid #F44532;

    svg {
      margin-left: 4px;
      height: 16px;
      path {
        fill: #006A56;
      }
    }
  }

  &.link {
    background: transparent;
    padding: 0;
    color: #F44532;
    font-size: 16px;
  }

}

h1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 136%;
  color: #434449;
}

.wrappper-content {
  display: flex;
  background: #F8F8F8;
  position: relative;
  z-index: 1;

  & .content {
    background: #fff;
    // width: calc(100vw - 330px);
    position: relative;
    z-index: 10;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

.footer-border {
  margin: 0 0 0 332px;
  background: #E6E7E7;
  height: 1px;
}

.separate {
  background: #E6E7E7;
  height: 1px;
}

span.required {
  font-size: 18px;
  color: #F44532;
}

.info {
  &-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;

    path {
      fill: #8D8F9B;
    }
    &.active path {
      fill: #FF7F00;
    }
  }

  &-block {
  background: #FFF8E2;
  border: 1px solid #EFEFF3;
  border-radius: 4px;
  padding: 24px 24px 0;

    p.content {
      color: #5C5E66;
      background: transparent;
      margin: 0 0 24px;
    }
  }
}

.scrollToTop {
  position: fixed;
  bottom: 80px;
  right: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #FBFBFC;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
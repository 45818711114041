.user-avatar {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin-right: 8px;
	background-color: #f8f8f8;

	img {
		max-width: 100%;
		object-fit: cover;
		object-position: center;
		transition: 0.6s;
	}
}

.developmentPage {
    &-wrapper {
        overflow-y: hidden;
        height: 100vh;

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            top: -18%;
            background-image: url('../../assets/images/development-bg.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &-top {
        height: 50%;
        z-index: 999;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F6F6F8;

        img:not(:last-child) {
            margin-bottom: 50px
        }
    }

    &-bottom {
        justify-content: center;
        padding: 0px 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #007F67;
        height: 50%;
    }

    &-header {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: white;
        font-family: 'Roboto';
    }

    &-footer {
        margin-top: 24px;
        text-align: center;
        color: white;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    }
}
.footer-project {
    background: #ffffff;
    width: -webkit-fill-available;
    height: 74px;
    border-top: 1px solid #E6E7E7;
    border-left: 1px solid #E6E7E7;
    display: flex;
    align-items: center;
    padding: 0 38px;
    z-index: 100;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
  
    &.fixed {
      position: fixed;
      bottom: 0;
    }
    
    div {
      display: flex;
      align-items: center;
    }

    .button-orange, .button-green {
      margin-left: 16px;
      height: 40px;
    }
  }

.sidebar {
    &.sidebar{
        border-right: 1px solid #E4E4EB;
        z-index: 20;
        background: #F6F6F8;
        height: calc(100vh - 80px);
        overflow: auto;
        position: fixed;
        left: 0;
        top: 80px;
        bottom: 0;
    }
    
    & .arrowup {
      display: flex;
      justify-content: center;
      height: 44px;
      align-items: center;
    }
  
    &-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 330px;
      height: 74px;
      padding: 16px;
      background-color: #ffffff;
      border-top: 1px solid #E4E4EB;
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      h4 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
      }

      button {
        padding: 0 26px;
      }
    }
  
    .sidebar-project {
      padding: 0 29px;
      .bg-img {
        margin-top: 100px;
      }
      .bg-union {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }

      p {
        font-style: italic;
        font-size: 16px;
        color: #5C5E66;
      }
    }
  }
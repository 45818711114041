.totalOrder {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    >div {
      margin-right: 22px;
      .title {
        font-size: 36px;
        color: #434449;
      }

      .subtitle {
        font-size: 14px;
        color: #8D8F9B;
      }
    }
}

.costLabel {
  display: flex;

  .costIcon {
    margin-left: 0.125rem;
    height: 16px;
  }
}

.costTooltip {
  .ant-tooltip-content{
      .ant-tooltip-inner{
          background: #666666;
          color: #ffffff;
          padding: 8px 12px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
      }
  }
}
.steps-wrapper {
    height: 56px;
    width: 100%;
    padding: 12px 100px 12px 38px;
    z-index: 100;

    h3 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #2D1940;
        padding: 0 0 25px 0;
    }

    .steps {

        .ant-steps-item {
            flex: none;
            order: 1;
            flex-grow: 1;

            .ant-steps-item-container .ant-steps-item-title {
                color: #404549;
                padding-right: 16px;

                &::after {
                    border-radius: 1px;
                    background-color: #404549;
                }
            }

            .ant-steps-item-icon {
                margin-right: 16px;
                background-color: unset;
            }

            &-wait {
                .ant-steps-item-icon {
                    border-color: #9CA1A7;
                    background: #FFFFFF;

                    span {
                        color: #404549;
                    }
                }
            }

            &-active {
                & .ant-steps-item-icon {
                    background: #1BAE92;
                    border-color: #1BAE92;
                }

                .ant-steps-item-container .ant-steps-item-title {
                    color: #404549;
                    font-weight: 500;
                }
            }

            &-finish {
                .ant-steps-item-icon {
                    border-color: #1BAE92;

                    path {
                        fill: #1BAE92;
                    }
                }

                .ant-steps-item-content .ant-steps-item-title::after {
                    background-color: #1BAE92;
                }
            }
        }
    }
}
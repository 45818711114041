.auth-modal .ant-modal-content {
   border-radius: 10px;

   & .ant-modal-body {
      padding: unset;
   }
}

.wrapper-sign {
   display: flex;

   .logotype {
      width: 370px;
      border-radius: 10px 0 0 10px;
      max-height: 100vh;
      min-height: 75vh;
      background: url('../../../public/images/sign-up.svg') bottom right no-repeat, #434449;

      img {
         width: 245px;
         margin: 60px auto auto 32px;
      }
   }

   .sign-form {
      border-radius: 0 10px 10px 0;
      background: #ffffff;
  
      h1 {
         margin: 40px auto 25px 40px;
      }

      form {
         padding: 0 40px;
         max-height: 500px;
         overflow-y: auto;
      }
   }

   p {
      font: normal 12px/16px 'Roboto', sans-serif;
      color: #5c5e66;
   }

   .buttons-wrapper {
      padding: 16px 38px;
      .buttons {
         display: flex;
         justify-content: space-between;
         align-items: center;
         & .button-orange {
            &:disabled {
               background: rgba(255, 127, 0, 0.4);
            }
         }
      }
   }
}

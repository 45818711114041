.sentText-block {
   text-align: center;
   display: grid;
   grid-template-columns: 484px;
   grid-template-rows: 40px 40px;
   row-gap: 24px;
   padding: 0px 40px;
   
   & p {
      padding-top: 60px;
      font-size: 14px;
   }
}

.password-reset-form {
   display: grid;
   align-items: center;
   grid-template-columns: 124px 360px;
   grid-template-rows: 40px 40px 40px 40px 40px 40px;
   row-gap: 24px;
   padding-top: 15px;
   font: normal 16px/16px 'Roboto', sans-serif;
   color: #5c5e66;

   & input[type='text'] {
            width: 100%;
            height: 40px;
            padding: 0 12px;
            background: #ffffff;
            border: 1px solid #dde3e8;
            border-radius: 4px;
            outline-width: 0;
         }

   & input[type='password'] {
      width: 100%;
      height: 40px;
      padding: 0 12px;
      background: #ffffff;
      border: 1px solid #dde3e8;
      border-radius: 4px;
      outline-width: 0;
   }

   & button {
      margin: 20px 0 0 20px;
      border-radius: 4px;
   }

   & .buttons {
      display: flex;
      justify-content: flex-start;

      & .button-orange {
         order: -1;
         padding: 0 16px;
         margin: 20px 0 0 0;
      }

      & .button-white {
         padding: 0 14px;
      }
   }

   .error-message {
      color: #ff0000;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin: 6px 0 0 0;
   }
}
